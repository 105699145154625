<template>
  <section>
    <el-row v-loading.fullscreen.lock="fullscreenLoading" :gutter="30">
      <el-col :span="2">
        <el-button plain @click="gotoClientPage">Back</el-button>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple">
          <el-tabs
            :tab-position="tabPosition"
            style="height: 200px"
            @tab-click="handleTabClick"
            class="left-aligned-tabs"
          >
            <el-tab-pane label="Keys & Credentials"></el-tab-pane>
            <el-tab-pane label="Redirect URL's"></el-tab-pane>
            <el-tab-pane label="Webhooks"></el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
      <el-col :span="18" class="tab-container">
        <h2 class="activeTab-style">{{ activeTab }}</h2>
        <div
          v-if="activeTab === 'Keys & Credentials'"
          v-loading="credentialSectionLoading"
        >
          <h5>Keys</h5>
          <p>
            Use these keys to set up Oauth for your environments.You can
            <span><a href="#" style="color: #3a86fc">learn more here</a></span>
            and try the flow in the
            <span
              ><a href="#" style="color: #3a86fc"
                >Oauth 2.0 Playground.</a
              ></span
            >
          </p>
          <el-row :gutter="4">
            <el-col :span="18">
              <div class="mb-1">
                <label>Client ID</label>
                <el-input
                  v-model="clientAppCredentials.clientId"
                  :readonly="readonly"
                  @input="preventInputOnClientAppId"
                >
                  <template slot="append">
                    <el-button
                      slot="append"
                      icon="el-icon-copy-document"
                      v-if="isClientIdCopied === false"
                      @click="
                        copyTheTextInTheInputBox(
                          clientAppCredentials.clientId,
                          'isClientIdCopied'
                        )
                      "
                    ></el-button>
                    <span v-else>Copied</span>
                  </template>
                </el-input>
              </div>
              <div class="mb-1">
                <label>Client Secret</label>
                <el-input
                  v-model="this.clientAppCredentials.clientSecret"
                  :readonly="readonly"
                  @input="preventInputOnClientAppId"
                >
                  <template slot="append">
                    <el-button
                      slot="append"
                      icon="el-icon-copy-document"
                      v-if="isClientSecretCopied === false"
                      @click="
                        copyTheTextInTheInputBox(
                          clientAppCredentials.clientSecret,
                          'isClientSecretCopied'
                        )
                      "
                    ></el-button>
                    <span v-else>Copied</span>
                  </template>
                </el-input>
              </div>
            </el-col>
          </el-row>
        </div>
        <div
          v-else-if="activeTab === 'Redirect URL\'s'"
          v-loading="redirectUrlSectionLoading"
        >
          <p class="mb-2">
            Redirect URL that your app serves to users upon
            authentication.Redirect URL requests must come from a web server.The
            URL itself can not be an IP address or a URL fragment or relative
            path.You can create up to 25 Redirect URL's.
          </p>
          <p>
            Development URL requests can be
            <span style="color: #030000; font-weight: 700">HTTP</span> or
            <span style="color: #030000; font-weight: 700">HTTPS</span>
          </p>
          <div class="mb-2">
            <div class="mb-1 text-right">
              <el-button type="primary" @click="addClientRedirectURL"
                >Add URL</el-button
              >
            </div>
            <el-table
              :data="redirectURLData"
              style="width: 100%; border: 1px solid #e1e2e3"
            >
              <el-table-column
                v-for="(column, index) in redirectUrlHeaders"
                :key="index"
                :prop="column.prop"
                :label="column.label"
                :width="column.width"
              >
                <template slot-scope="scope">
                  <span
                    v-if="column.prop === 'link'"
                    style="color: red !important; text-align: center"
                  >
                    <el-input
                      size="medium"
                      placeholder="Please enter Redirect URL"
                      v-model="scope.row.link"
                    >
                    </el-input>
                  </span>
                  <span
                    v-else-if="column.prop === 'actions'"
                    style="text-align: center"
                  >
                    <el-button
                      plain
                      type="danger"
                      size="mini"
                      class="px-2 ml-2"
                      @click="onDelete(scope.row)"
                    >
                      <i class="el-icon-delete"></i>
                    </el-button>
                  </span>
                  <span v-else style="text-align: center">{{
                    scope.row[column.prop]
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mb-2 text-right">
            <el-button>Cancel</el-button>
            <el-button type="primary">Save</el-button>
          </div>
        </div>
        <div v-else v-loading="webhookSectionLoading">
          <el-scrollbar wrap-style="max-height:70vh;" ref="configureScroll">
            <h3>Endpoints</h3>
            <p>
              Webhooks in eSigns are notifications triggered by specific events
              within your document workflow. When certain actions, such as
              document creation, signing, or completion, occur, eSigns sends a
              <span style="color: #080807; font-weight: 200">POST</span> request
              to the designated endpoint URL provided by your application. These
              notifications serve to keep your application updated in real-time
              with the latest developments in your document processes. Please
              note that webhooks are intended for development and integration
              purposes to enhance the functionality and automation of your
              eSigns platform.
              <el-link type="primary">Learn more</el-link>
            </p>
            <br /><br />
            <el-row :gutter="4" class="webhook-top-level">
              <el-col :span="20">
                <div class="mb-1">
                  <label><span style="color: red">*</span> Endpoint URL:</label>
                  <div class="mt-1">
                    <el-input
                      size="medium"
                      placeholder="Please enter Endpoint"
                      v-model="webhookInfoObj.webhookEndpoint"
                      @input="showWebhookUrlError"
                      @blur="generateVerifierToken"
                    >
                    </el-input>
                    <p v-if="urlError" style="color: red" class="mt-1">
                      {{ urlError }}
                    </p>
                  </div>
                </div>
                <div class="mb-1">
                  <label>Verifier Token:</label>
                  <div class="mt-1">
                    <el-input
                      size="medium"
                      v-model="webhookInfoObj.webhookVerifierToken"
                      :readonly="readonly"
                    >
                      <template slot="append">
                        <el-button
                          @click="
                            copyTheTextInTheInputBox(
                              webhookInfoObj.webhookVerifierToken,
                              'isVerifierTokenCopied'
                            )
                          "
                          v-if="isVerifierTokenCopied === false"
                          slot="append"
                          icon="el-icon-copy-document"
                        ></el-button>
                        <span v-else>Copied</span>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="mb-1">
                  <label>Status</label>
                  <div class="mt-1">
                    <el-radio
                      v-model="webhookInfoObj.webhookState"
                      label="Active"
                      >Active</el-radio
                    >
                    <el-radio
                      v-model="webhookInfoObj.webhookState"
                      label="Inactive"
                      >Inactive</el-radio
                    >
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row class="mb-2 mt-2">
              <el-col :span="20">
                <h1 class="mb-1">Trigger Events</h1>
                <el-collapse>
                  <el-collapse-item
                    v-for="item in triggerEventsHeaders"
                    :title="item.title"
                    :name="item.name"
                    :key="item.name"
                  >
                    <div v-if="item.type == 'Document'">
                      <el-row class="mt-1">
                        <el-col :span="24">
                          <el-row :gutter="30">
                            <label class="mb-1 ml-2">Document Events:</label>
                            <el-checkbox-group
                              v-model="webhookInfoObj.events.document_events"
                            >
                              <el-col
                                :span="6"
                                v-for="item in documentEvents"
                                :key="item.key"
                              >
                                <el-checkbox
                                  :label="item.label"
                                  :value="item.key"
                                ></el-checkbox>
                              </el-col>
                            </el-checkbox-group>
                          </el-row>
                          <el-row :gutter="20">
                            <el-col :span="12" class="mt-1">
                              <el-switch
                                v-model="webhookInfoObj.enableIncludeData"
                                active-text="Include Data"
                              ></el-switch>
                              <div
                                v-if="webhookInfoObj.enableIncludeData === true"
                                class="mt-1"
                              >
                                <el-checkbox-group
                                  v-model="webhookInfoObj.includeData"
                                >
                                  <el-checkbox
                                    v-for="item in includingDataOptions"
                                    :key="item.key"
                                    :label="item.label"
                                  ></el-checkbox>
                                </el-checkbox-group>
                                <div class="mt-1">
                                  <el-button
                                    type="primary"
                                    @click="showSampleJsonDialogBox"
                                    >Preview JSON Payload Sample
                                    (trimmed)</el-button
                                  >
                                </div>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>
                    <div v-else-if="item.type == 'Contacts'">
                      <el-row class="mt-1">
                        <el-col :span="16">
                          <el-row :gutter="20">
                            <el-col :span="12">
                              <label class="mb-1">Contacts Events:</label>
                              <el-checkbox-group
                                v-model="webhookInfoObj.events.contact_events"
                              >
                                <el-checkbox
                                  v-for="item in contactsEvents"
                                  :key="item.key"
                                  :label="item.label"
                                  :value="item.key"
                                ></el-checkbox>
                              </el-checkbox-group>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>
                    <div v-else>
                      <el-row class="mt-1">
                        <el-col :span="16">
                          <el-row :gutter="20">
                            <el-col :span="12">
                              <label class="mb-1">Template Events:</label>
                              <el-checkbox-group
                                v-model="webhookInfoObj.events.template_events"
                              >
                                <el-checkbox
                                  v-for="item in templateEvents"
                                  :key="item.key"
                                  :label="item.label"
                                  :value="item.key"
                                ></el-checkbox>
                              </el-checkbox-group>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-col>
            </el-row>
            <div class="mb-2">
              <el-button>Cancel</el-button>
              <el-button
                type="primary"
                @click="saveWebhookInformation"
                >Save</el-button
              >
            </div>
          </el-scrollbar>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="Preview JSON Payload Sample (trimmed)"
      :visible.sync="payloadDialogVisible"
      width="50%"
      :close-on-click-modal="false"
      style="text-align: left"
    >
      <div>
        <div class="mb-1 sample-payload-content">
          <el-scrollbar wrap-style="max-height:70vh;" ref="configureScroll">
            <h4>
              <pre><code>{{formatedPayload}}</code></pre>
            </h4>
          </el-scrollbar>
        </div>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ClientApp Portal",
  data() {
    return {
      webhookSectionLoading: false,
      credentialSectionLoading: false,
      redirectUrlSectionLoading: false,
      activeTab: "Keys & Credentials",
      tabPosition: "left",
      isVerifierTokenCopied: false,
      isClientIdCopied: false,
      isClientSecretCopied: false,
      urlError: "",
      clientAppCredentials: {
        clientId: "",
        clientSecret: "",
      },
      webhookInfoObj: {
        webhookEndpoint: "",
        webhookVerifierToken: "",
        events: {
          document_events: [],
          template_events: [],
          contact_events: [],
        },
        enableIncludeData: false,
        includeData: [],
        webhookState: "Active",
      },
      fullscreenLoading: false,
      triggerEventsHeaders: [
        { name: "1", title: "Document and Recipients", type: "Document" },
        { name: "2", title: "Template", type: "Template" },
        { name: "3", title: "Contacts", type: "Contacts" },
      ],
      templateEvents: [
        { key: "template_created", label: "Template Created" },
        { key: "template_modified", label: "Template Modified" },
        { key: "template_deleted", label: "Template Deleted" },
      ],
      contactsEvents: [
        { key: "contact_created", label: "Contact Created" },
        { key: "contact_modified", label: "Contact Modified" },
        { key: "contact_deleted", label: "Contact Deleted" },
      ],
      documentEvents: [
        { key: "document_sent", label: "Sent" },
        { key: "document_completed", label: "Completed" },
        { key: "document_finish", label: "Finished" },
        { key: "document_declined", label: "Declined" },
        { key: "document_approved", label: "Approved" },
        { key: "document_rejected", label: "Rejected" },
        { key: "document_voided", label: "Voided" },
        { key: "document_resent", label: "Resent" },
        { key: "document_corrected", label: "Corrected" },
        { key: "document_deleted", label: "Deleted" },
        { key: "document_finish_later", label: "Finish later" },
        { key: "document_note_sent", label: "Document note sent" },
        { key: "document_note_updated", label: "Document node updated" },
      ],
      includingDataOptions: [
        { key: "document_settings", label: "Document Settings" },
        { key: "document_users", label: "Recipients" },
        { key: "company_document_data", label: "Documents" },
        { key: "document_fields", label: "Document Fields" },
      ],
      readonly: true,
      redirectUrlHeaders: [
        {
          prop: "order",
          label: "Order",
          width: 180,
        },
        {
          prop: "link",
          label: "Link",
          width: 620,
        },
        {
          prop: "actions",
          label: "Actions",
          width: 180,
        },
      ],
      redirectURLData: [],
      payloadDialogVisible: false,
    };
  },
  computed: {
    formatedPayload() {
      let payload = {
        event: "document-sent",
        apiVersion: "v2.1",
        data: {
          accountId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
          recipientId: "1",
          envelopeId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
          envelopeSummary: {
            status: "sent",
            emailSubject: "API Demo subject",
            emailBlurb: "Please sign this Demo Envelope",
            signingLocation: "online",
            enableWetSign: "true",
            allowMarkup: "false",
            allowReassign: "true",
            createdDateTime: "2019-12-12T18:23:36.6800000Z",
            lastModifiedDateTime: "2019-12-12T18:23:36.6970000Z",
            statusChangedDateTime: "2019-12-12T18:23:36.6800000Z",
            useDisclosure: "false",
            sender: {
              userName: "John Smith",
              userId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
              accountId: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
              email: "johnsmith@docusign.com",
            },
            recipients: "{...}",
            envelopeDocuments: [
              {
                documentId: "1",
                documentIdGuid: "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                name: "BlankDoc.txt",
                type: "content",
                order: "1",
                display: "inline",
                includeInDownload: "true",
                signerMustAcknowledge: "no_interaction",
                templateRequired: "false",
                authoritative: "false",
                PDFBytes: "",
              },
            ],
          },
        },
      };
      return JSON.stringify(payload, null, 2);
    },
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("oauth", [
      "getVerifierToken",
      "getWebhookData",
      "getFetchWebhook",
      "getClientAppDataById",
    ]),
  },
  async mounted() {
    await this.fetchAndSetClientAppInfoById(this.$route.params.appId);
    this.setRedirectUrls();
    this.setWebhookInfoByCompanyId();
  },
  methods: {
    handleTabClick(tab) {
      this.activeTab = tab.label;
      if (tab.label === "Webhooks") {
        this.webhookSectionLoading = true;
        setTimeout(() => {
          this.webhookSectionLoading = false;
        }, 500);
      } else if (tab.label === "Keys & Credentials") {
        this.credentialSectionLoading = true;
        setTimeout(() => {
          this.credentialSectionLoading = false;
        }, 500);
      } else if (tab.label === "Redirect URL's") {
        this.redirectUrlSectionLoading = true;
        setTimeout(() => {
          this.redirectUrlSectionLoading = false;
        }, 500);
      }
    },
    async copyTheTextInTheInputBox(value, key) {
      try {
        if (key === "isClientIdCopied") {
          this.isClientIdCopied = true;
          await navigator.clipboard.writeText(value);
          setTimeout(() => {
            this.isClientIdCopied = false;
          }, 5000);
        }
        if (key === "isClientSecretCopied") {
          this.isClientSecretCopied = true;
          await navigator.clipboard.writeText(value);
          setTimeout(() => {
            this.isClientSecretCopied = false;
          }, 5000);
        }
        if (key === "isVerifierTokenCopied") {
          this.isVerifierTokenCopied = true;
          await navigator.clipboard.writeText(value);
          setTimeout(() => {
            this.isVerifierTokenCopied = false;
          }, 5000);
        }
      } catch (err) {
        console.log("copyTheTextInTheInputBox", err);
      }
    },
    showWebhookUrlError() {
      let valid = this.validateUrl();
      if (valid) {
        this.urlError = "";
      } else {
        this.urlError = "Please enter a valid URL";
        this.webhookInfoObj.webhookVerifierToken = "";
      }
    },
    validateUrl() {
      try {
        const parsedURL = new URL(this.webhookInfoObj.webhookEndpoint);
        if (
          (parsedURL.protocol === "http:" || parsedURL.protocol === "https:") &&
          parsedURL.hostname &&
          parsedURL.hostname !== "localhost" &&
          this.isValidHostname(parsedURL.hostname)
        ) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    },
    isValidHostname(hostname) {
      const domainRegex = /^[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*$/;
      return domainRegex.test(hostname);
    },
    async generateVerifierToken() {
      if (
        this.webhookInfoObj.webhookEndpoint &&
        this.webhookInfoObj.webhookEndpoint.length > 0 &&
        this.urlError === ""
      ) {
        await this.$store.dispatch("oauth/fetchVerifierToken");
        if (this.getVerifierToken && this.getVerifierToken.data) {
          this.webhookInfoObj.webhookVerifierToken = this.getVerifierToken.data;
        }
      }
    },
    async saveWebhookInformation() {
      this.webhookSectionLoading = true;
      let params = {
        url: this.webhookInfoObj.webhookEndpoint,
        events: await this.getDataKeys(
          this.webhookInfoObj.events,
          "webhookEvents"
        ),
        enableIncludeData: this.webhookInfoObj.enableIncludeData,
        includeData: await this.getDataKeys(
          this.webhookInfoObj.includeData,
          "includeData"
        ),
        verifierToken: this.webhookInfoObj.webhookVerifierToken,
        workspaceId: this.getAuthenticatedUser.current_workspace.company_id,
        state: this.webhookInfoObj.webhookState,
        appId: this.$route.params.appId,
      };
      await this.$store.dispatch("oauth/saveWebhookData", params);
      if (this.getWebhookData && this.getWebhookData.data) {
        this.setWebhookInfoByCompanyId();
        this.webhookSectionLoading = false;
        this.$notify.success({
          title: "Success",
          message: "Successfully saved webhook",
        });
        return;
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in saving webhook",
        });
        return;
      }
    },
    async fetchAndSetClientAppInfoById(clientAppId) {
      this.credentialSectionLoading = true;
      let params = {
        appId: clientAppId,
      };
      await this.$store.dispatch("oauth/fetchClientAppById", params);
      if (this.getClientAppDataById && this.getClientAppDataById.success) {
        this.clientAppCredentials.clientId =
          this.getClientAppDataById.data.clientId;
        this.clientAppCredentials.clientSecret =
          this.getClientAppDataById.data.clientSecret;
      }
      this.credentialSectionLoading = false;
    },
    setRedirectUrls() {
      if (
        this.getClientAppDataById &&
        this.getClientAppDataById.data &&
        this.getClientAppDataById.data.redirectUris
      ) {
        this.redirectURLData = this.getClientAppDataById.data.redirectUris.map(
          (uri, index) => {
            return {
              order: index + 1,
              link: uri,
              actions: "delete",
            };
          }
        );
      }
    },
    async setWebhookInfoByCompanyId() {
      try {
        if (
          this.getAuthenticatedUser &&
          this.getAuthenticatedUser.current_workspace &&
          this.getAuthenticatedUser.current_workspace.company_id
        ) {
          let params = {
            company_id: this.getAuthenticatedUser.current_workspace.company_id,
            appId: this.$route.params.appId,
          };
          await this.$store.dispatch("oauth/fetchWebhookBycompanyId", params);
          if (
            this.getFetchWebhook &&
            this.getFetchWebhook.data &&
            this.getFetchWebhook.data.length > 0
          ) {
            this.webhookInfoObj.webhookEndpoint =
              this.getFetchWebhook.data[0].url;
            this.webhookInfoObj.webhookVerifierToken =
              this.getFetchWebhook.data[0].verifierToken;
            this.webhookInfoObj.webhookState =
              this.getFetchWebhook.data[0].state;
            this.webhookInfoObj.enableIncludeData =
              this.getFetchWebhook.data[0].enableIncludeData;
            this.webhookInfoObj.includeData = this.getEvents(
              this.getFetchWebhook.data[0]?.includeData,
              "includeData"
            );
            this.webhookInfoObj.events.document_events = this.getEvents(
              this.getFetchWebhook.data[0]?.events?.[0].operations?.[
                "document_events"
              ],
              "document_events"
            );
            this.webhookInfoObj.events.template_events = this.getEvents(
              this.getFetchWebhook.data[0]?.events?.[0].operations?.[
                "template_events"
              ],
              "template_events"
            );
            this.webhookInfoObj.events.contact_events = this.getEvents(
              this.getFetchWebhook.data[0]?.events?.[0].operations?.[
                "contact_events"
              ],
              "contact_events"
            );
            this.fullscreenLoading = false;
          } else {
            this.webhookInfoObj.webhookEndpoint = "";
            this.webhookInfoObj.webhookVerifierToken = "";
          }
        }
      } catch (err) {
        console.log("err in getting webhook of workspace", err);
      }
    },
    getEvents(arr, eventName) {
      if (eventName === "document_events") {
        return arr.map((e) => {
          let isFound = this.documentEvents.find((f) => f.key === e);
          if (isFound) {
            return isFound.label;
          }
        });
      } else if (eventName === "contact_events") {
        return arr.map((e) => {
          let isFound = this.contactsEvents.find((f) => f.key === e);
          if (isFound) {
            return isFound.label;
          }
        });
      } else if (eventName === "template_events") {
        return arr.map((e) => {
          let isFound = this.templateEvents.find((f) => f.key === e);
          if (isFound) {
            return isFound.label;
          }
        });
      } else if (eventName === "includeData") {
        return arr.map((e) => {
          let isFound = this.includingDataOptions.find((f) => f.key === e);
          if (isFound) {
            return isFound.label;
          }
        });
      }
      // let events = [];
      // if (obj && obj.events && obj.events[0] && obj.events[0].operations) {
      //   obj.events[0].operations[eventName].forEach((el) => {
      //     if (el.includes("_")) {
      //       events.push(el.replace(/_/g, " "));
      //     } else {
      //       events.push(el);
      //     }
      //   });
      //   return events;
      // }
    },
    preventInputOnClientAppId(event) {
      event.preventDefault();
    },
    addClientRedirectURL() {
      let urlObj = {
        order: this.redirectURLData.length + 1,
        link: "",
        actions: "delete",
      };
      this.redirectURLData.push(urlObj);
    },
    onDelete(row) {
      console.log("row is on delete event:", row);
    },
    gotoClientPage() {
      this.$router.go(-1);
    },
    showSampleJsonDialogBox() {
      this.payloadDialogVisible = true;
    },
    getDataKeys(arr, key) {
      if (key === "includeData") {
        if (arr && arr.length > 0 && this.includingDataOptions) {
          return arr.map((e) => {
            let isFound = this.includingDataOptions.find((f) => f.label === e);
            if (isFound) {
              return isFound.key;
            }
          });
        } else {
          return [];
        }
      } else if (key === "webhookEvents") {
        if (
          arr.document_events &&
          arr.document_events.length > 0 &&
          this.documentEvents
        ) {
          arr.document_events = arr.document_events.map((e) => {
            let isFound = this.documentEvents.find((f) => f.label === e);
            if (isFound) {
              return isFound.key;
            }
          });
        }
        if (
          arr.template_events &&
          arr.template_events.length > 0 &&
          this.templateEvents
        ) {
          arr.template_events = arr.template_events.map((e) => {
            let isFound = this.templateEvents.find((f) => f.label === e);
            if (isFound) {
              return isFound.key;
            }
          });
        }
        if (
          arr.contact_events &&
          arr.contact_events.length > 0 &&
          this.contactsEvents
        ) {
          arr.contact_events = arr.contact_events.map((e) => {
            let isFound = this.contactsEvents.find((f) => f.label === e);
            if (isFound) {
              return isFound.key;
            }
          });
        }
        return arr;
      }
    },
  },
};
</script>
<style scoped>
.tab-container {
  border: 1px solid #bdbbbb;
  border-radius: 5px;
  height: 100%;
  padding: 20px;
}
.activeTab-style {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #bdbbbb;
}
.webhook-top-level {
  border-bottom: 1px solid #bdbbbb;
}
.left-aligned-tabs .el-tabs__item {
  text-align: left !important;
}
.sample-payload-content {
  padding: 5px;
  background-color: #e6edf7;
  color: #050505;
  font-weight: 700;
  border-radius: 2px;
}
</style>
